import applicationRoutes from "./applications.routes";
import checklistsRoutes from "./checklists.routes";
import diariesRoutes from "./diaries.routes";
import processesRoutes from "./processes.routes";
import propertiesRoutes from "./properties.routes";

export default [
  {
    path: "sbpm",
    component: () => import("@/modules/sbpm/views/Template"),
    children: [
      ...propertiesRoutes,
      ...diariesRoutes,
      ...applicationRoutes,
      ...checklistsRoutes,
      ...processesRoutes,
    ],
  },
];
