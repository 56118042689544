import * as Utils from "@/utils/index.js";

import ArnpriorRoutes from "@/modules/arnprior/routes/index.routes";
import ContactFormRoutes from "@/modules/contact-forms/routes/index.routes";
import CraigtounRoutes from "@/modules/craigtoun/routes/index.routes";
import CustomersRoutes from "@/modules/customers/routes/index.routes";
import DrumRoutes from "@/modules/drum/routes/index.routes";
import EasyletsRoutes from "@/modules/easylets/routes/index.routes";
import EcommerceRoutes from "@/modules/ecommerce/routes/index.routes";
import FathomRoutes from "@/modules/fathom/routes/index.routes";
import FlightModeRoutes from "@/modules/flightmode/routes/index.routes";
import GrogreenRoutes from "@/modules/grogreen/routes/index.routes";
import IncludemRoutes from "@/modules/includem/routes/index.routes";
import LHLRoutes from "@/modules/lhl/routes/index.routes";
import LpbRoutes from "@/modules/lpb/routes/index.routes";
import MelroseRoutes from "@/modules/melrose/routes/index.routes";
import NewsRoutes from "@/modules/news/routes/index.routes";
import OpportunitiesRoutes from "@/modules/opportunities/routes/index.routes";
import PageContentRoutes from "@/modules/page-content/routes/index.routes";
import PawRoutes from "@/modules/paw/routes/index.routes";
import PipelineRoutes from "@/modules/pipeline/routes/index.routes";
import SEORoutes from "@/modules/seo/routes/index.routes";
import SbpmRoutes from "@/modules/sbpm/routes/index.routes";
import TaskingRoutes from "@/modules/tasking/routes/index.routes";
import TeamRoutes from "@/modules/team/routes/index.routes";
import UsersRoutes from "@/modules/users/routes/index.routes";
import Vue from "vue";
import VueRouter from "vue-router";
import WatsonRoutes from "@/modules/watson/routes/index.routes";
import WorkloadRoutes from "@/modules/workload/routes/index.routes";
import store from "@/stores/";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: { auth: false },
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn === true) {
        next({ name: "index" });
        return;
      }
      next();
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/auth/ForgottenPassword"),
    meta: { auth: false },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password-form",
    component: () => import("@/views/auth/ResetPasswordForm"),
    meta: { auth: false },
  },
  {
    path: "/loading",
    name: "loader",
    component: () => import("@/views/AppLoader.vue"),
    props: (route) => ({
      to: route.params.to,
    }),
  },
  {
    path: "/",
    name: "index",
    beforeEnter: (to, from, next) => {
      // Determine whether user has access to a single app or multiple
      // If they have access to a single, then direct straight to the app in question
      if (store.getters.sumOfApps > 1) {
        next({ name: "app-selector" });
        return;
      } else {
        next({
          name: "app-dashboard",
          params: { id: store.getters.singleAppId },
        });
      }
    },
    meta: { auth: true },
  },
  {
    path: "/setup",
    name: "account-setup",
    beforeEnter: (to, from, next) => {
      if (store.getters.userStatus === 0) {
        next();
        return;
      } else {
        next({ name: "app-selector" });
      }
    },
    component: () => import("@/views/AccountSetup.vue"),
    meta: { auth: true },
  },
  {
    path: "/setup-complete",
    name: "account-setup-complete",
    beforeEnter: (to, from, next) => {
      if (from.name === "account-setup") {
        next();
        return;
      } else {
        next({ name: "index" });
      }
    },
    component: () => import("@/views/AccountSetupComplete.vue"),
    meta: { auth: false },
  },
  {
    path: "/account-settings",
    component: () => import("@/views/AppTemplate"),
    meta: { auth: true },
    props: () => ({
      sidebarItems: Utils.getAccountSidebarItems(),
      userInitials: store.getters.userInitials,
      sumOfApps: store.getters.sumOfApps,
      apps: store.getters.apps,
      appName: store.getters.sumOfApps === 1 ? store.getters.apps[0].name : "",
      appLongLogo: Utils.getAppLongLogo(),
      showReturnToApp: true,
      user: store.getters.user,
    }),
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => next({ name: "account-settings" }),
      },
      {
        path: "general",
        name: "account-settings",
        component: () => import("@/views/account/GeneralSettings"),
      },
      {
        path: "change-password",
        name: "account-change-password",
        component: () => import("@/views/account/ChangePassword"),
      },
    ],
  },
  {
    path: "/integrations",
    meta: { auth: true, integration: true },
    children: [
      {
        path: "xero",
        name: "xero-callback",
        beforeEnter: (to, from, next) => {
          next({ name: "app-selector" });
        },
      },
    ],
  },
  {
    path: "/apps",
    name: "app-selector",
    beforeEnter: (to, from, next) => {
      // Determine whether user has access to a single app or multiple
      if (store.getters.sumOfApps > 1) {
        next();
        return;
      } else {
        next({
          name: "app-dashboard",
          params: { id: store.getters.singleAppId },
        });
      }
    },
    component: () => import("@/views/AppSelector"),
    props: () => ({
      apps: store.getters.apps,
      userInitials: store.getters.userInitials,
    }),
    meta: { auth: true },
  },
  {
    path: "/apps/:id",
    beforeEnter: (to, from, next) => {
      if (Utils.hasAppAccess(parseInt(to.params.id))) {
        next();
      } else {
        next({ name: "app-selector" });
      }
    },
    component: () => import("@/views/AppTemplate"),
    props: (route) => ({
      sidebarItems: Utils.getSidebarItems(parseInt(route.params.id)),
      userInitials: store.getters.userInitials,
      user: store.getters.user,
      appName: Utils.getAppName(parseInt(route.params.id)),
      appLongLogo: Utils.getAppLongLogo(parseInt(route.params.id)),
      sumOfApps: store.getters.sumOfApps,
      apps: store.getters.apps,
      hasWorkloadAccess: Utils.appHasModule(
        "workload",
        parseInt(route.params.id)
      ),
    }),
    meta: { auth: true },
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => {
          next({ name: "app-dashboard", params: { ...to.params } });
        },
      },
      {
        path: "dashboard",
        name: "app-dashboard",
        component: () => import("@/views/AppDashboard"),
      },
      {
        path: "settings",
        name: "app-settings",
        component: () => import("@/views/app-settings/Dashboard"),
        meta: {
          hasPermission: "view-app-settings",
        },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("appSettings/appHasPassword", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
      },
      ...UsersRoutes,
      ...SbpmRoutes,
      ...TeamRoutes,
      ...CustomersRoutes,
      ...IncludemRoutes,
      ...OpportunitiesRoutes,
      ...PipelineRoutes,
      ...WorkloadRoutes,
      ...PawRoutes,
      ...LHLRoutes,
      ...DrumRoutes,
      ...FlightModeRoutes,
      ...PageContentRoutes,
      ...WatsonRoutes,
      ...ContactFormRoutes,
      ...CraigtounRoutes,
      ...EcommerceRoutes,
      ...MelroseRoutes,
      ...SEORoutes,
      ...NewsRoutes,
      ...FathomRoutes,
      ...TaskingRoutes,
      ...EasyletsRoutes,
      ...ArnpriorRoutes,
      ...LpbRoutes,
      ...GrogreenRoutes,
    ],
  },
  {
    path: "*",
    meta: { auth: true },
    component: () => import("@/views/PageNotFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (from.name === null && to.name !== "loader") {
    next({
      name: "loader",
      params: {
        to: to,
      },
    });
    return;
  }

  if (to.matched.some((record) => record.meta.auth)) {
    if (!store.getters.isLoggedIn) {
      next({ name: "login" });
      return;
    }

    if (store.getters.userStatus === 0 && to.name !== "account-setup") {
      next({ name: "account-setup" });
      return;
    }
  }

  if (
    to.matched.some((record) => record.meta.hasOwnProperty("hasPermission"))
  ) {
    const index = to.matched.findIndex((record) =>
      record.meta.hasOwnProperty("hasPermission")
    );

    if (
      !Utils.hasPermission(
        to.matched[index].meta.hasPermission,
        parseInt(to.params.id)
      )
    ) {
      next({ name: "app-dashboard" });
      return;
    }
  }

  next();
});

export default router;
