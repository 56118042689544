import applicationsStore from "./applications.store";
import checklistsStore from "./checklists.store";
import diariesStore from "./diaries.store";
import processesStore from "./processes.store";
import propertiesStore from "./properties.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  applicationsStore,
  propertiesStore,
  checklistsStore,
  diariesStore,
  processesStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
